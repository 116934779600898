import { FlowBase, identificationTypes } from "./flow-base";

class OrangeBotswana extends FlowBase {
    handleIntegratorIframeIdentity() {
        //since heIframeIdentity has no error handled add a timeout then call identifyOnError
        this.iframeTimeout = window.setTimeout(() => {
            this.checkIdentify()
        }, this.identifyTimeout * 1000);
        const base = this.integratorIdentifyUrl.split('?')[0];

        this.integrator.customHeIframeIdentifyBaseUrl(
            this.identifyEndpoint,
            {
                return: window.location.origin + window.location.pathname,
                metadata: this.metadata,
            }
        );
    }
}

const OrangeBotswanaFlowModule = new OrangeBotswana('Orange Botswana OTP', identificationTypes.IFRAME, {
    OTP: true,
    subscribeClick: true,
    inactiveTimeout: true,
})
window.FlowModule = OrangeBotswanaFlowModule;
